.c-bonus {
	display: grid;
	align-items: start;
	grid-gap: 1.5rem;
	padding: 1rem;
  	box-shadow: 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
  	background-color: $color_white;
	border-radius: 1.4rem;
	margin-bottom: 1rem;

	&__image {
		position: relative;

		img {
			border-radius: 1.4rem;
			display: block;
			width: 100%;
		}

		p {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			opacity: 0.83;
  			background-color: $color_blue_2;
  			padding: 1.5rem 1rem;
  			color: $color_white;
  			z-index: 1;
  			border-bottom-left-radius: 1.4rem;
  			border-bottom-right-radius: 1.4rem;
  			font-weight: bold;
		}
	}

	&__action {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;
		transform: translateY(50%);
		text-align: center;
	}

	& > p {
		margin-top: 3rem;
		margin-bottom: 2rem;
	}
}