.s-columns {
	display: grid;
	grid-gap: 3rem;

	@include media(">=t") {
		grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
		align-items: start;
	}

	@include media(">=t-m", "<t") {
        grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    }
}