.c-hero {
	position: relative;
	text-align: center;
	padding-top: 4rem;
	background: 0 0 url('../img/default/header_mobile_bg.png') no-repeat;
	background-size: cover;

	@include media(">m") {
		background: 0 0 url('../img/default/header_desktop_bg.png') no-repeat;
		background-size: cover;
	}

	&__content {
		padding: 4rem 0 7rem;
	}

	&__search {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 100;
		transform: translate(0, 50%);
		text-align: left;
		background-color: $color_gray;
        border-radius: 1.4rem;

        @include media(">=t") {
	        display: grid;
	        grid-gap: 1.5rem;
	        grid-template-columns: 1fr auto;
	        align-items: center;
	    }
	}
}