.s-listing {
    display: grid;
    grid-gap: 3rem;
	align-items: start;

	@include media(">=t") {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
	}
}
