.s-form {
    display: grid;
    grid-gap: 1.8rem;
    margin-bottom: 1.5rem;
    text-align: left;
    
    &--horizontal {
        @include media(">=t-m") {
            grid-template-columns: 16.5rem 1fr;
        }
    }

    &:last-child {
    	margin-bottom: 0;
    }
}
