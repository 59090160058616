.c-logo {
	font-size: 2.05rem;
	font-weight: bold;
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	justify-content: center;
	grid-gap: 0.5rem;
	text-decoration: none;
	color: $color_white;

	img {
		margin: 0;
	}

	small {
		color: $color_blue;
		background-color: $color_white;
		border-radius: 0.6rem;
		padding: 0.2rem 0.5rem 0.1rem;;
		margin-left: 0.3rem;
		font-size: 1.1rem;
		vertical-align: middle;
		letter-spacing: 0.044rem;
		text-transform: uppercase;
		line-height: 1.8rem;
	}

	@include media("<=m") {
		font-size: 2.2rem;

		&--hide-mobile {
			display: none;
		}
	}

	&:hover {
		text-decoration: none;
	}
}