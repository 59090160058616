article {
    background-color: $color_white;
    padding: 2rem;
    border-radius: 2.3rem;
    box-shadow: 3.3px 7.3px 14px 0 rgba(58, 58, 58, 0.11);

    @include media(">t") {
        padding: 3rem;
    }
    
    h1 {
        text-align: center;
        margin: 4rem 4rem 3rem;
    }

    h2, h3, h4, h5 {
        margin-top: 2.5rem;
        text-align: left;
    }

    p {
        margin-top: 1rem;
    }

    ol + p,
    ul + p,
    .js-width + p,
    table + p {
        margin-top: 4rem;
    }

    p + table,
    p + .js-width {
        margin-top: 3rem;
    }

    ol,
    ul {
        margin-top: 3rem;

        li {
            padding-left: 1.9rem;
        }

        li::before {
            content: "";
            position: absolute;
            width: 0.7rem;
            height: 100%;
            left: 0;
            background: 0 0.7rem url('../img/svg/dot_gray.svg') no-repeat;
        }
    }

    address {
        font-style: normal;
    }

    blockquote {
        border-radius: 0.3rem;
        border: solid 1px $color_gray_2;
        background-color: $color_gray_3;
        margin: 1rem 0;
        padding: 2rem 1.5rem;
    }

    img:not(.ico):not(.pic) {
        margin-top: 3rem;
    }
}