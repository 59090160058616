.c-header {
    display: grid;
	grid-template-columns: 2.8rem auto 1fr 35rem;
	grid-template-rows: 5.5rem;
	align-items: center;
	grid-gap: 1.5rem;

	&__logo {
		justify-content: start;
	}

	&__nav {
		li {
			float: left;
			margin: 0;
		}

		a {
			padding: 1rem 2rem;
			color: $color_yellow;
			font-weight: bold;

			&:hover {
				background-color: $color_yellow;
				color: $color_blue;
				text-decoration: none;
			}
		}
	}

	&__search {
		text-align: right;
	}

	@include media("<t") {
		grid-template-columns: 2.8rem auto 2.8rem;
		justify-items: center;

		&__nav {
			display: none;
		}
	}
}
