/* ==========================================================================
   Header scss files
   ========================================================================== */

/* Normalize
   ========================================================================== */

@import "node_modules/normalize.css/normalize";

/* Include media
   ========================================================================== */

@import "node_modules/include-media/dist/_include-media.scss";

/* Base
   ========================================================================== */

@import "partials/base/variables";
@import "partials/base/mixins";
@import "partials/base/reset";
@import "partials/base/grid";
@import "partials/base/typography";

/* Vendor
   ========================================================================== */

@import "partials/vendor/init";

/* Functionality
   ========================================================================== */

@import "partials/functionality/init";

/* Contents
   ========================================================================== */

@import "partials/contents/init_header";

/* Components
   ========================================================================== */

@import "partials/components/init_header";

/* Layouts
   ========================================================================== */

@import "partials/layouts/init_header";

/* Modifiers
   ========================================================================== */

@import "partials/base/modifiers";
