.s-wrapper {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: $breakpoint_laptop;

    &--small {
    	max-width: $breakpoint_tablet;
    }

    &--hero {
    	max-width: $breakpoint_tablet_middle;
    }

    @include media("<=l") {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}