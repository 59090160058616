h1, h2, h3, h4, h5 {
	margin: 0;
	padding: 0;

	img {
		margin-right: 1rem;
	}

	@include media("<t") {
		text-align: center;
	}
}

h1 {
	font-size: 2.1rem;
	line-height: 2.7rem;

	@include media(">=m") {
		font-size: 4.5rem;
		line-height: 5.6rem;
	}
}

h2 {
	font-size: 1.9rem;
	line-height: 2.6rem;

	@include media(">=m") {
		font-size: 2.9rem;
		line-height: 3.8rem;
	}
}

h3 {
	font-size: 1.6rem;

	@include media(">=m") {
		font-size: 2.3rem;
	}
}

h4 {
	@include media(">=m") {
		font-size: 1.7rem;
	}
}

h5 {
	@include media(">=m") {
		font-size: 1.5rem;
	}
}