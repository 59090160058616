aside {
    ol,
    ul {
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    input {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}
