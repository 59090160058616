.c-breadcrumb {
	white-space: nowrap;
	margin-top: 5rem;

	& > * {
		position: relative;
		display: inline-block;
		margin: 0;
		padding: 0 0 0 3rem;

		&::before {
			content: "/";
			position: absolute;
			left: 1.2rem;
			color: $color_gray_2;
		}

		&:first-child {
			background: 0 0.2rem url('../img/svg/ico_home.svg') no-repeat;

			&::before {
				content: "";
			}
		}
	}

	&::after {
	  content: "";
	  clear: both;
	  display: table;
	}
}