/* Colors
   ========================================================================== */
@each $pallet, $value in $scotch-colors {
    @each $name in $value {
        $i: index($value, $name);

        .color-#{$pallet}-#{$i} {
            color: #{$name};
            fill: #{$name};
        }
    }
}

/* Spaces for the grid
   ========================================================================== */
@for $i from 1 through 5 {
    .space-h-#{$i} {
        margin-left: (5px * $i);
        margin-right: (5px * $i);
    }

    .space-v-#{$i} {
        margin-top: (5px * $i);
        margin-bottom: (5px * $i);
    }
}

@each $name, $value in $breakpoints {
    @include media("<=#{$name}") {
        @for $i from 1 through 5 {
            .space-h-#{$i}\@#{$name} {
                margin-left: (5px * $i);
                margin-right: (5px * $i);
            }

            .space-v-#{$i}\@#{$name} {
                margin-top: (5px * $i);
                margin-bottom: (5px * $i);
            }
        }
    }
}

/* Visibility
   ========================================================================== */

@each $name, $value in $breakpoints {
    .hide-#{$name} {
        @include media(">=#{$name}") {
            display: none;
        }
    }

    .show-#{$name} {
        display: none;

        @include media(">=#{$name}") {
            display: inline-block;
        }
    }
}