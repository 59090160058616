
/*  Apply a natural box layout model to all elements
   ========================================================================== */
*, *:before, *:after {
  box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

strong {
    font-weight: 700;
}

button, input, optgroup, select, textarea {
    padding: 0;
    font-family: inherit;
}

svg {
    fill: currentColor;
}

figure {
    margin: 0;
}

figure img {
    display: block;
}

a {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}