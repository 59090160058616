
/* Colors
========================================================================== */
$color_white:       #fff;
$color_black:       #000000;
$color_black_2:     #3a3a3a;

$color_gray:        #ededed;
$color_gray_2:      #dbdbdb;
$color_gray_3:      #f4f4f4;
$color_gray_4:      #e5e5e5;
$color_gray_5:      #ededed;

$color_yellow:      #dff04c;
$color_yellow_2:    #c0d255;

$color_blue:        #3f3fbb;
$color_blue_2:      #223881;
$color_blue_3:      #5050d2;
$color_blue_4:      #3b3eb3;

$color_red:         #df3d58;
$color_red_2:       rgba(223, 61, 88, 0.1);

$color_green:       #62ca54;
$color_green_2:     rgba(98, 202, 84, 0.1);

$scotch-colors: (
    'black': (
        $color_black,
        $color_black_2
    ),
    'yellow': (
        $color_yellow,
        $color_yellow_2
    ),
    'blue': (
        $color_blue,
        $color_blue_2,
        $color_blue_3,
        $color_blue_4
    ),
    'gray': (
        $color_gray,
        $color_gray_2,
        $color_gray_3,
        $color_gray_4,
        $color_gray_5
    )
);

/* Fonts
========================================================================== */
$font_normal: 'Barlow Semi Condensed', sans-serif;

/* Breakpoints
========================================================================== */
$breakpoint_laptop: 1410px;
$breakpoint_laptop_small: 1280px;
$breakpoint_tablet: 1200px;
$breakpoint_tablet_middle: 980px;
$breakpoint_mobile: 670px;
$breakpoint_mobile_small: 480px;
$breakpoint_mobile_thin: 370px;

$breakpoints: (
    l: $breakpoint_laptop,
    l-s: $breakpoint_laptop_small,
    t: $breakpoint_tablet,
    t-m: $breakpoint_tablet_middle,
    m: $breakpoint_mobile,
    m-s: $breakpoint_mobile_small,
    m-t: $breakpoint_mobile_thin
);
