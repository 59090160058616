ol,
ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        position: relative;
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.bullets li {
        padding-left: 1.9rem;

        &::before {
            content: "";
            position: absolute;
            width: 0.7rem;
            height: 100%;
            left: 0;
            background: 0 0.7rem url('../img/svg/dot_blue.svg') no-repeat;
        }
    }

    &.padding li {
        padding: 1rem;

        @include media(">=t") {
            padding: 1.5rem;
        }
    }

    &.striped li:nth-child(even) {
        background-color: $color_gray;
    }
}