form {
    .c-alert {
        display: none;
    }

    &.is-error .c-alert--error,
    &.is-success .c-alert--success {
        display: block;
    }

    &.is-success .c-captcha,
    &.is-success button {
        display: none;
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

label {
    padding-top: 1rem;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
    display: block;
    width: 100%;
    border-radius: 1.4rem;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
    outline: 0;
    border: 0;
    box-shadow: inset 0.7px 0.8px 2px 0px rgba(58, 58, 58, 0.25);
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    font-weight: 300;

    &:focus {
        outline: none;
    }
}

input[type="text"],
input[type="email"] {
    &.search {
        background: 95% 1rem url('../img/svg/ico_search.svg') no-repeat $color_white;
    }
}

textarea {
    min-height: 13rem;
}

input[type="checkbox"] {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $color_white;
    box-shadow: inset 0.7px 0.8px 2px 0px rgba(58, 58, 58, 0.25);

    &:checked:after {
        content: '';
        position: absolute;
        top: 0.35rem;
        left: 0.3rem;
        width: 1.2rem;
        height: 0.9rem;
        background: url('../img/svg/check_box.svg') no-repeat;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: 0px 2px 0 0 $color_gray;
    border: 1px solid $color_white;
    outline: none;
    padding: 0.7rem 0.6rem 0.9rem;
    font-weight: 300;
    border-radius: 1.4rem;
    color: $color_black_2;
    background: calc(100% - 1rem) 1.5rem url('../img/svg/select_arrow.svg') no-repeat $color_white;

    &::-ms-expand {
        display: none;
    }
}

.button,
button {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    outline: none;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.06rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 1.4rem;
    padding: 1rem 3.5rem;
    
    background-image: linear-gradient(to top, $color_blue, $color_blue_2);
    color: $color_white;

    &.block {
        width: 100%;
    }

    &.small {
        padding: 0.6rem 0.7rem 0.7rem;    
    }

    &.large {
        padding: 1.2rem 3.3rem 1.4rem;
    }

    &.gray {
        color: $color_black_2;
        background-image: none;
        box-shadow: -2.7px -3px 7px 0 $color_white, 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
        background-color: $color_gray_3;
    }

    &.blue {
        color: $color_yellow;
        background-image: none;
        box-shadow: -2.7px -3px 7px 0 $color_blue_3, 2.7px 2.9px 7px 0 $color_blue_2;
        background-color: $color_blue_4;
    }

    &.flat {
        &-yellow {
            box-shadow: 0 0 1.6rem 0 $color_yellow;
            background-image: linear-gradient(to top, $color_yellow, $color_yellow_2);
            color: $color_blue_2;
        }

        &-blue {
            color: $color_yellow;
            background-image: linear-gradient(to top, $color_blue, $color_blue_2);
        }
    }

    &.center {
        width: auto;
        margin: 0 auto;
        display: inline-block;
    }

    &:hover {
        text-decoration: none;

        @include media(">t") {
            opacity: 0.85;
        }
    }
}

*::-ms-clear {
    display: none;
}

::placeholder {
    opacity: 1;
    color: $color_gray_2;
}
