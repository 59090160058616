.sr {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-uppercase {
    text-transform: uppercase;
}

.align-center {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.align-left {
    display: block;
    margin-bottom: 3rem;

    @include media(">m") {
        float: left;
        margin-right: 3rem;
    }
}

.align-right {
    display: block;
    margin-bottom: 3rem;

    @include media(">m") {
        float: right;
        margin-left: 3rem;
    }
}

.margin-top {
    margin-top: 3rem;
}