.s-main {
	display: grid;
	grid-gap: 3rem;
	margin-top: 4rem;
	margin-bottom: 4rem;
	
	@include media(">=t") {
	    grid-template-columns: 1fr auto;
	    grid-template-rows: auto auto;
	}

	@include media(">=l") {
	    grid-template-columns: auto 33rem;
	}
}
