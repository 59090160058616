.c-ico {
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 0;
    vertical-align: baseline;

    svg {
        width: 100%;
        height: 100%;
    }
}