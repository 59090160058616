.c-showmore {
	position: relative;

	&:not(.is-opened) &__content {
		max-height: 11.5rem;
		margin-bottom: 4rem;

		@include media(">t-m") {
			max-height: 8.5rem;
		}
	}

	&:not(.is-opened) &__more {
		visibility: visible;
	}

	&__content {
		overflow: hidden;
		text-align: center;
		font-weight: 300;
		color: $color_white;
		padding: 2rem 2rem 0;

		@include media(">t-m") {
			line-height: 2.1rem;
			font-size: 1.6rem;
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__more {
		visibility: hidden;
	}
}