.js-width {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 0;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    transition: visibility 0s linear 0s, opacity 300ms;

    &:not([style]),
    &[style=""] {
    	visibility: hidden;
    	opacity: 0;
    	transition: visibility 0s linear 300ms, opacity 300ms;
    }
}