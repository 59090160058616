a {
    color: $color_blue;
    display: inline-block;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.small {
    	font-size: 1.5rem;
    }

    .c-ico {
        margin-right: 1rem;
    }
}