.c-carousel {
	&__items {
		white-space: nowrap;
	}

	&__items &__item {
		white-space: initial;
		display: inline-block;
	}

	&--featured &__items {
		padding-left: 0.5rem;

		@include media("<l") {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}

	&--featured &__item {
		margin-right: 1.5rem;
		max-width: 33rem;

		&:last-child {
			margin-right: 0.5rem;

			@include media("<l") {
				margin-right: 2rem;
			}

			@include media(">=t", "<l") {
				margin-right: 0.5rem;
			}
		}

		@include media("<=l-s") {
			max-width: 27rem;	
		}
	}

	@include media("<l") {
		margin-left: -2rem;
	}

	@include media("<l") {
		margin-right: -2rem;
	}

	@include media(">=t", "<l") {
		padding-right: 1.75rem;
	}
}
