.c-search {
    text-align: left;
    
    &--wrapper {
        padding: 1rem;
    }

    &__desktop.is-opened &__inactive,
    &__mobile.is-opened &__inactive,
    &__desktop:not(.is-opened) &__active,
    &__mobile:not(.is-opened) &__active,
    &--hide:not(.is-opened) {
        display: none;
    }

    &__input {
        position: relative;
    }

    &__clear {
        position: absolute;
        right: 0.2rem;
        top: 0.2rem;
        bottom: 0.2rem;
        width: 3.5rem;
        z-index: 1;
        border-top-right-radius: 1.4rem;
        border-bottom-right-radius: 1.4rem;
        background: 50% 50% url('../img/svg/ico_reset.svg') no-repeat $color_white;
        cursor: pointer;
    }

    input {
        text-indent: 3.5rem;
        background: 1.5rem 50% url('../img/svg/ico_search.svg') no-repeat $color_white;
        margin-bottom: 0;
    }

    &--ico {
        &-right input {
            text-indent: 0;
            background-position: calc(100% - 1.5rem) 50%;
        }

        &-blue input {
            background-image: url('../img/svg/ico_search_blue.svg');
        }
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: $color_white;
        box-shadow: 1.6px 3.7px 7px 0 rgba(58, 58, 58, 0.11);
        border-radius: 1.4rem;
        margin: 0;
        max-height: 40rem;
        overflow-y: scroll;

        li {
            margin: 0;
        }
    }

    @include media("<t") {
        &__desktop {
            display: none;
        }
    }

    @include media(">=t") {
        &__mobile {
            display: none;
        }
    }
}
