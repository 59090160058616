.c-rating {
	position: relative;
	margin-right: 0.4rem;
	background: url('../img/svg/diamond_white.svg');
	height: 1.6rem;
	width: calc(1.8rem * 5);

	&--stars10 {
		width: calc(1.8rem * 10);
	}

	&__progress {
		position: absolute;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	    overflow: hidden;
	    background: url('../img/svg/diamond.png');
	}
}