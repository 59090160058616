/* Base
   ========================================================================== */
html {
  font-size: 62.5%; /* 1 rem = 10px */
}

body {
  min-width: 320px;
  font-family: $font_normal;
  font-weight: 300;
  font-size: 1.5rem; /* default mobile font is 15px */
  line-height: 1.9rem;
  color: $color_black_2;
  background: $color_gray;

  @include media(">m") {
    font-size: 1.7rem; /* default desktop font is 16px */
  }
}
